<template>
    <div class="personalList">
        <app-list :opt="opt" @get="onGet" ref="myList"></app-list>
        <el-dialog title="充值" :visible.sync="dialogVisibleRecharge" :close-on-click-modal="false" width="30%">
            <div class="row">
                <div class="row-title">充值到账商家名称：</div>
                <div>{{merchantName}}</div>
            </div>
            <div class="row">
                <div class="row-title">商家身份信息：</div>
                <div>{{cardNo}}</div>
            </div>
            <el-form ref="rechargeForm" :model="rechargeForm" :rules="rules">
                <el-form-item label-width='158px' label="请输入充值金额(元)" prop="amount">
                    <el-input style="display: inline-block;" placeholder="请输入充值金额"
                        v-model.trim="rechargeForm.amount" clearable>
                    </el-input>
                </el-form-item>
            </el-form>
            <div class="submitBox">
                <el-button class="submitBox-button" type="primary" @click="sbumit()">确认充值</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import cabinetUtil from "@utils/cabinetUtil.js"
    let validAmount = (rule, value, callback) => {
        var reg = /(^[1-9]{1}[0-9]*$)|(^[0-9]*\.[0-9]{1,2}$)/
        if (!value) {
            callback(new Error("请输入充值金额"));
        } else if (!reg.test(value)) {
            callback(
                new Error("请输入正数，且小数点后最多保留2位")
            );
        } else if (parseFloat(value) > parseFloat(100000.00)) {
            callback(new Error("充值金额不能超过十万"));
        } else {
            callback();
        }

    };
    export default {
        data() {
            let _this = this;
            return {
                dialogVisibleRecharge: false,
                merchantId: '',
                merchantName: '',
                cardNo:'',
                rechargeForm: {
                    amount: ''
                },
                rules: {
                    amount: [{
                        required: true,
                        validator: validAmount,
                        trigger: ['change', 'blur']
                    }],
                },
                opt: {
                    search: [{
                            key: "name",
                            label: "商家名称",
                        },
                        {
                            key: "bankPhone",
                            label: "手机号码",
                        },
                        {
                            key: "state",
                            label: "状态",
                            type: 'select',
                            opt: {
                                list: [{
                                        value: 0,
                                        label: '待审核',
                                    },
                                    {
                                        value: 1,
                                        label: '审核通过',
                                    }
                                ]
                            }
                        },
                    ],
                    columns: [{
                            label: "商家名称",
                            key: "name"
                        },
                        {
                            label: "性别",
                            key: "sexStr"
                        },

                        {
                            label: "证件类型",
                            key: "cardTypeStr"
                        },
                        {
                            label: "证件号码",
                            key: "cardNoStr"
                        },
                        {
                            label: "手机号码",
                            key: "bankPhone"
                        },
                        {
                            label: "联系地址",
                            key: "address"
                        },
                        {
                            label: "注册时间",
                            key: "createdTime"
                        },
                        {
                            label: "状态",
                            key: "stateStr"
                        },
                        {
                            label: "操作",
                            key: "action",
                            type: "action-back",
                            opt: {
                                list: [{
                                    label: "详情",
                                    on(row, cb) {
                                        _this.goDetail(row, 2);
                                    }
                                }, {
                                    label: "审核",
                                    on(row, cb) {
                                        _this.goDetail(row, 1);
                                    }
                                }, {
                                    label: "充值",
                                    on(row, cb) {
                                        if(row.merchantType == 3) {
                                            return _this.$message({
                                                showClose: true,
                                                    message: "经销类型商家，暂不支持充值!",
                                                    type: "warning"
                                            });
                                        }
                                        _this.dialogVisibleRecharge = true;
                                        _this.rechargeForm.amount = '';
                                        _this.merchantId = row.id;
                                        _this.merchantName = row.name;
                                        if(row.cardNo && row.cardNo.length > 0) {
                                            _this.cardNo = row.cardNo.slice(0,4) + '********' + row.cardNo.slice(row.cardNo.length - 4,row.cardNo.length);
                                        }
                                        // 对表单进行重置
                                        _this.$nextTick(() => {
                                            _this.$refs["rechargeForm"].resetFields();
                                        })
                                    }
                                }, {
                                    label: "同步分润比例",
                                    on(row, cb) {
                                        _this.synchronizationFun(row);
                                    }
                                }]
                            }
                        }
                    ],
                    buttons: []
                },
            };
        },
        created() {
            console.log("room-list created!!");
        },
        deactivated() {
            this.dialogVisibleRecharge = false;
        },
        activated() {},

        methods: {
            onGet(opt) {
                this.myColums = [];
                let dto = {
                    pageNumber: opt.skip,
                    pageSize: opt.limit,
                    type: 1,
                    roleType: 1,
                    ...opt.searchForm
                };
                this.post("/mall-service/merchant/v1/page", dto, {
                    isUseResponse: false
                }).then(res => {
                    for (let i = 0; i < res.data.length; i++) {
                        let item = res.data[i];
                        item.action = [false, false];
                        if (item.state == 0) {
                            item.action = [true, true, false];
                        } else {
                            item.action = [true, false, true];
                        }
                        // 商家审核通过 且未同步过分润比例的才显示 同步
                        if(item.state == 1) {
                            // 1-已同步过 0-未同步
                            if (item.isSync == 1) {
                                item.action[3] = false;
                            } else {
                                item.action[3] = true;
                            }
                        }else {
                            item.action[3] = false;
                        }
                        item.stateStr = ["待审核", "审核通过"][item.state];
                        item.sexStr = ["男", "女"][item.sex - 1];
                        if (item.cardType == 1) {
                            item.cardTypeStr = "身份证";
                        }
                        item.cardNoStr = item.cardNo ? item.cardNo.slice(0, 2) + '******' + item.cardNo.slice(
                            item.cardNo.length - 2, item.cardNo.length) : '--';
                        item.bankPhone = item.bankPhone ? item.bankPhone.slice(0,3) + '****' + item.bankPhone.slice(item.bankPhone.length - 4, 
                            item.bankPhone.length) : '--'
                        item.address = item.address ? item.address : '--';
                    }
                    opt.cb(res);
                });
            },
            goDetail(row, checkFlage) {
                let path = '';
                path = "/main/merchantManage/merchant/detail";
                this.$router.push({
                    path: path,
                    query: {
                        id: row.id,
                        state: row.state,
                        checkFlage: checkFlage,
                        type: row.type,
                    }
                })
            },
            sbumit() {
                this.$refs['rechargeForm'].validate((valid) => {
                    if (valid) {
                        let dto = {
                            merchantId: this.merchantId,
                            amount: cabinetUtil.regYuanToFen(Number(this.rechargeForm.amount),100),
                        };
                        this.post('/mall-service/allinpay/v1/recharge/apply',dto,{
                            isUseResponse: true
                        }).then(res => {
                            if(res.data.code == 0) {
                                this.$message({
                                    message: '提交成功',
                                    type: 'success'
                                })
                                this.dialogVisibleRecharge = false;
                            }
                        })
                    }
                })
            },
            synchronizationFun(row) {
                let dto = {
                    merchantId: row.id,
                    merchantName: row.name,
                }
                this.post('/mall-service/category/sync', dto, {
                    isUseResponse: true
                }).then(res => {
                    if (res.data.code == 0) {
                        this.$message({
                            message: '同步成功',
                            type: 'success'
                        })
                        this.$refs.myList.onRefresh();
                    }
                })
            }
        }
    };
</script>
<style lang="scss">
    .personalList {
        height: calc(100% - 130px);

        .submitBox {
            text-align: center;
        }

        .row {
            display: flex;
            width: 540px;
            padding: 10px 0;
        }

        .row-title {
            width: 158px;
            text-align: right;
        }

    }
</style>